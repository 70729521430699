import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withTransaction } from '@elastic/apm-rum-react';
import { isEmpty, isNil } from 'ramda';
import { useLocation } from 'react-router';
import { actions, selectors } from '@/Store';
import Preloader from '@/Layouts/containers/Preloader';
import pageViewedAnalytics from '@/Analytics/Page';
import { menuItemClicked } from '@/Analytics/Segment';
import ErrorMessage from '@/Components/Message';
import MovieHeroBanner from '@/Components/HeroBanner/MovieHeroBanner';
import { AppId, GroupLayout, Movie } from '@skytvnz/sky-app-store/lib/types/graph-ql';
import useMedia from '@/Hooks/useMedia';
import useIsLoaded from '@/Hooks/useIsLoaded';
import { getImageType } from '@/Utils/VideoImage';
import Rail from '@/Components/Rail';
import TileType from '@/Components/Rail/Tiles/TileType';
import TileSize from '@/Components/Rail/Tiles/TileSize';
import ShowHeroBanner from '@/Components/HeroBanner/ShowHeroBanner';
import Carousel from '@/Components/Carousel';
import { PageType } from '@/Components/HeroBanner/HeroTypes';
import useIsNewHistory from '@/Hooks/useIsNewHistory';
import usePagination from '@/Hooks/usePagination';
import styles from './styles.module.scss';

export interface StateType {
  pageName: string;
  sectionId: string;
}
const Home: React.FC = () => {
  const dispatch = useDispatch();
  const isNewHistory = useIsNewHistory();
  const location = useLocation<StateType>();
  const isLoading = useSelector(selectors.home.isLoading);
  const heroes = useSelector(selectors.home.heroes);
  const LastPaginatedRail = useRef({ id: '', index: 0 });
  const heroesDuration: number = useSelector(selectors.home.heroesDuration);
  const rails = useSelector(selectors.home.rails);
  const { isMediaM } = useMedia();
  // Ref to track first load
  const isFirstLoad = useRef(true);
  const { railPageSize } = usePagination();
  const [apiIsLoading, setApiIsLoading] = useState(false);

  const isLoaded = useIsLoaded(isLoading);
  // If there are already some Rails or Hero, not show loading
  const isContentLoading = isNil(heroes) && (isNil(rails) || isEmpty(rails)) && isLoading;
  const isContentLoadingError = isNil(heroes) && (isNil(rails) || isEmpty(rails)) && isLoaded;

  const imageType = getImageType(PageType.Home, isMediaM);
  const onTileClick = useCallback(
    (railId: string, positionIndex: number) => {
      const isContinueWatchingRail = rails?.some(
        rail => rail.id === railId && rail.layout === GroupLayout.ContinueWatching,
      );
      if (isContinueWatchingRail) {
        LastPaginatedRail.current = { id: '', index: 0 };
      } else {
        LastPaginatedRail.current = { id: railId, index: positionIndex };
      }
    },
    [rails],
  );
  const onTilesPaginate = useCallback(
    id => {
      setTimeout(() => {
        dispatch(
          actions.home.fetchRailById(
            id,
            location.state ? location?.state?.sectionId : PageType.Home.toLowerCase(),
            AppId.SkyGoWeb,
            railPageSize.current,
          ),
        );
        // the timeout value equals to the paginate transition duration
      }, 600);
    },
    [dispatch, railPageSize, location],
  );

  useEffect(() => {
    if (isFirstLoad.current) {
      setApiIsLoading(true);
      dispatch(
        actions.home.fetchContent({
          initialRailSize: railPageSize.current,
          reloadRailId: isNewHistory ? undefined : LastPaginatedRail.id,
          sectionId: location?.state?.sectionId,
          appId: AppId.SkyGoWeb,
        }),
      ).finally(() => {
        setApiIsLoading(false);
        isFirstLoad.current = false;
      });
    }
  }, [dispatch, location, isNewHistory, railPageSize]);

  useEffect(() => {
    menuItemClicked({ menu_item: location?.state?.pageName });
    pageViewedAnalytics(location?.state?.pageName);
  }, [location]);
  return (
    <Preloader isLoading={apiIsLoading || isContentLoading}>
      {isContentLoadingError ? (
        <ErrorMessage
          buttonText="Reload"
          onButtonClick={() => {
            window.location.reload();
          }}
        />
      ) : (
        <>
          {heroes && (
            <Carousel className={styles.heroBanner} duration={heroesDuration}>
              {heroes.map(hero => {
                const image = hero?.[imageType].uri || '';
                return hero.__typename === 'Show' ? (
                  <ShowHeroBanner
                    show={hero}
                    image={image}
                    imageType={imageType}
                    pageType={PageType.Home}
                  />
                ) : (
                  <MovieHeroBanner
                    movie={hero as Movie}
                    image={image}
                    imageType={imageType}
                    pageType={PageType.Home}
                  />
                );
              })}
            </Carousel>
          )}
          {rails &&
            rails
              .filter(rail => rail.contentPage)
              .map(({ contentPage, ...rail }) => (
                <Rail
                  key={`rail_${rail.id}`}
                  id={rail.id}
                  heading={rail.title || ''}
                  subTitle={rail.subtitle || ''}
                  type={TileType.Media}
                  assets={contentPage.content}
                  layout={rail.layout || GroupLayout.RailLandscape}
                  railGroup={rail.__typename}
                  size={TileSize.Small}
                  hasNextPage={contentPage.pageInfo?.hasNextPage}
                  hasError={!!rail.error}
                  isTilesLoading={rail.isLoading}
                  initialIndex={
                    !isNewHistory && LastPaginatedRail.id === rail.id ? LastPaginatedRail.index : 0
                  }
                  tileClickHandler={onTileClick}
                  tilesLoadHandler={onTilesPaginate}
                />
              ))}
        </>
      )}
    </Preloader>
  );
};

export default withTransaction('Home', 'component')(Home);
