import { init, configKeys, ModuleName } from '@skytvnz/sky-app-store';
import storage from 'redux-persist/lib/storage';
import Sentry from '@/Utils/Sentry';
import { EXP_PRODUCT_CODE, SPOTIFY_API_URL } from './constants';

const config = {
  [configKeys.EXP_API_URL]: process.env.EXP_API_URL,
  [configKeys.SPOTIFY_API_URL]: SPOTIFY_API_URL,
  [configKeys.PRODUCT_ID]: EXP_PRODUCT_CODE,
  devTools: process.env.ENV !== 'PRODUCTION',
  persistenceConfig: {
    key: `sky_${process.env.APP_BRAND}_web`,
    storage: storage,
    whitelist: ['parentalPin', 'customer'],
  },
};

export { createStore, createPersistor, actions, selectors, hooks, utils, enums };

const excludedModules = [
  ModuleName.PlayerNative,
  ModuleName.Download2Go,
  ModuleName.MyStuff,
  ModuleName.Chromecast,
  ModuleName.Network,
];

const { createStore, createPersistor, actions, selectors, hooks, utils, enums } = init(
  config,
  Sentry,
  excludedModules,
);
