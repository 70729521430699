import { FAQ_URL } from './constants';

const externalApplicationLinksConfig = {
  linkAccountUrl: process.env.SKY_ACCOUNT_LINK_URL || '',
  subscribeUrl: process.env.SUBSCRIBE_SKY_URL || '',
  upgradeSubscriptionUrl: process.env.UPGRADE_SUBSCRIPTION_URL || '',
  faqUrl: FAQ_URL,
  myAccount: process.env.MY_ACCOUNT_URL || '',
};

export default externalApplicationLinksConfig;
