import * as SentryBrowser from '@sentry/browser';
import { Breadcrumb } from '@sentry/browser';
import sessionId from '@/Utils/SessionId';
import uuid from '@/Utils/Uuid';
import { SENTRY_DSN } from '@/Config/constants';
import pkg from '../../package.json';
import { getDeviceId, getMinusVersionUserAgent } from './Device';

const isReportingEnabled =
  window.location.hostname !== 'localhost' && window.location.hostname !== '127.0.0.1';
const release = pkg.version.split('-')[0];
const releaseWithAppName = `${pkg.name.split('/')[1]}@${release}`;

const Sentry = {
  /*
   * Initialises Sentry
   */
  init: () => {
    if (isReportingEnabled) {
      SentryBrowser.init({
        dsn: SENTRY_DSN,
        environment: process.env.ENV,
        release: releaseWithAppName,
      });

      SentryBrowser.configureScope(scope => {
        scope.setTag('platform', 'web');
        scope.setTag('sessionId', sessionId);
      });
    }
  },
  /*
   * Identifies a user by userAccountId, email and profileId .
   *
   * @param {Object} userInfo
   */
  identify: (userInfo, activeProfileId) => {
    if (isReportingEnabled) {
      SentryBrowser.setUser({
        id: activeProfileId || 'unauthenticated',
        username: (userInfo && userInfo.sub) || 'unauthenticated',
      });

      SentryBrowser.configureScope(scope => {
        scope.setTag('userProfile', activeProfileId || 'unauthenticated');
      });
    }
  },
  /*
   * Clearing all information about the user
   *
   */
  reset: () => {
    if (isReportingEnabled) {
      SentryBrowser.setUser(null);

      SentryBrowser.configureScope(scope => {
        scope.setTag('userProfile', 'unauthenticated');
      });
    }
  },
  /*
   *
   * Send a custom error message to Sentry
   *
   */
  captureMessage: message => {
    if (isReportingEnabled) {
      SentryBrowser.captureMessage(message);
    }
  },
  /*
   *
   * Send a custom exception error message to Sentry
   *
   */
  captureException: exception => {
    if (isReportingEnabled) {
      SentryBrowser.captureException(new Error(exception));
    }
  },
  /*
   *
   * add a breadcrumb for error debugging on Sentry
   *
   */
  addBreadcrumb: (breadcrumb: Breadcrumb) => {
    if (isReportingEnabled) {
      SentryBrowser.addBreadcrumb(breadcrumb);
    }
  },
  /*
   *
   * returns unique session id
   *
   */
  getSessionId: () => {
    if (isReportingEnabled) {
      return sessionId;
    }
    return '';
  },
  /*
   *
   * generates new correlation id every time it is getting called
   *
   */
  getCorrelationId: () => {
    if (isReportingEnabled) {
      const correlationId = uuid();
      SentryBrowser.configureScope(scope => {
        scope.setTag('correlationId', correlationId);
      });

      return correlationId;
    }
    return '';
  },
  /*
   *
   * returns release number
   *
   */
  getRelease: () => {
    if (isReportingEnabled) {
      return release;
    }
    return '0.0.0+local';
  },
  /*
   *
   * returns application id
   *
   */
  getAppId: () => {
    return 'skyGo-web';
  },
  /*
   *
   * returns device ID
   *
   */
  getDeviceId: () => {
    return getDeviceId();
  },
  getUserAgent: () => getMinusVersionUserAgent(),
};
export const getApiHeaders = () => {
  return {
    'x-sky-app-version': Sentry.getRelease(),
    'x-sky-app-id': Sentry.getAppId(),
    'x-session-id': Sentry.getSessionId(),
    'x-correlation-id': Sentry.getCorrelationId(),
    'x-product-id': 'skyGo', // settings.brandName,
    'x-sky-device-id': Sentry.getDeviceId(),
    ...(Sentry?.getUserAgent ? { 'user-agent': Sentry.getUserAgent() } : {}),
  };
};
export default Sentry;
