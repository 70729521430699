import React from 'react';
import * as SentryBrowser from '@sentry/browser';
import ErrorMessage from '@/Components/Message/ErrorMessage';

type ErrorBoundaryProps = {
  children: React.ReactNode;
  errorChild?: React.ReactNode;
  onError?: () => void;
};
type ErrorBoundaryStates = {
  hasError: boolean;
};

// we have to use class component to handle lifecycle: getDerivedStateFromError
export default class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryStates
> {
  public constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  public static getDerivedStateFromError() {
    return { hasError: true };
  }

  public componentDidCatch(error: any, errorInfo: any) {
    const { onError } = this.props;
    onError?.();
    SentryBrowser.addBreadcrumb({
      category: 'Error Boundary',
      message: errorInfo,
    });
    SentryBrowser.captureException(error);
  }

  public handleReload = () => {
    this.setState({ hasError: false });
    window.location.reload();
  };

  public render() {
    const { hasError } = this.state;
    const { errorChild, children } = this.props;
    if (hasError) {
      // This props 'errorChild' can be used in ErrorBounday, when we want an custom view as per need, to avoid App crash.
      if (errorChild) {
        return errorChild;
      }
      // This will render standard 'Something went wrong' page, if any issue in any component as it is added at Root level.
      return <ErrorMessage onButtonClick={this.handleReload} buttonText="Reload" />;
    }
    return children;
  }
}
