import React, { useCallback } from 'react';
import { Router } from 'react-router-dom';
import ScrollMemory from 'react-router-scroll-memory';
import { Provider } from 'react-redux';
import { useEvent } from 'react-use';
import { ModalProvider } from 'react-modal-hook';
import { CookiesProvider } from 'react-cookie';
import debounce from 'lodash.debounce';

import FeatureToggleProvider from '@/Components/FeatureToggle/FeatureToggleProvider';
import RegisterDevice from '@/Components/Device';
import AuthProvider from '@/Components/AuthProvider';
import AuthNotification from '@/Components/AuthNotification';
import AppHeader from '@/Components/AppHeader/AppHeader';
import ErrorBoundary from '@/Components/ErrorBoundary';
import { store } from '@/Store/configureStore';
import history from '@/Routes/History';
import Routes from '@/Routes';
import Layout from '@/Layouts/Default';

import styles from './App.module.scss';
import ProfileManager from './Components/ProfileManager';
import useDeviceInfo from './Hooks/useDeviceInfo';

/* Start of debugging component re-rendering */

// To enable the debugging logs,
// assign a `whyDidYouRender` to `true` to your component
// eg. `VideoPlayer.whyDidYouRender = true;`

// eslint-disable-next-line import/order, import/no-extraneous-dependencies
// import whyDidYouRender from '@welldone-software/why-did-you-render';

// if (process.env.NODE_ENV === 'development') {
//   whyDidYouRender(React, {
//     exclude: [/undefined/],
//     trackAllPureComponents: true,
//   });
// }
/* End of debugging component re-rendering */

const refreshHtmlRem = debounce(() => {
  const htmlClassNames = window.document.documentElement.classList;
  htmlClassNames.add(styles.htmlRem);
  htmlClassNames.remove(styles.htmlRem);
}, 200);

const App: React.FC = () => {
  const onResize = useCallback(refreshHtmlRem, []);

  useEvent('resize', onResize, window);
  const { isDeviceInfoReady } = useDeviceInfo();

  return isDeviceInfoReady ? (
    <Provider store={store}>
      <CookiesProvider>
        <AuthProvider>
          <ModalProvider>
            <FeatureToggleProvider authorizationKey={process.env.SPLIT_IO_AUTH_KEY}>
              <ErrorBoundary>
                <Router history={history}>
                  <AppHeader />
                  <AuthNotification />
                  <RegisterDevice />
                  <ScrollMemory />
                  <ProfileManager>
                    <Layout>
                      <Routes />
                    </Layout>
                  </ProfileManager>
                </Router>
              </ErrorBoundary>
            </FeatureToggleProvider>
          </ModalProvider>
        </AuthProvider>
      </CookiesProvider>
    </Provider>
  ) : (
    <></>
  );
};

export default App;
