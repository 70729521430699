import React, { useCallback, useEffect, useState } from 'react';
import useCustomerProfile from '@/Hooks/useCustomerProfile';
import { Button } from 'antd';
import classnames from 'classnames';
import ManageProfile, { CustomerProfileItem } from '@/Components/CustomerProfile/ManageProfile';
import {
  ADD_PROFILE,
  SELECT_PROFILE,
  MANAGE_PROFILE,
} from '@/Components/CustomerProfile/constants';
import { useDispatch } from 'react-redux';
import { actions } from '@/Store';
import { useHistory } from 'react-router';
import { Logo } from '@/brandedModules/skyGo/Assets/all';
import useMedia from '@/Hooks/useMedia';
import pageViewedAnalytics from '@/Analytics/Page';
import icon_plus from '@skytvnz/sky-app-style/lib/assets/svg/icons/icon_plus.color.svg';
import Avatar from '../../Components/Avatar';

import styles from './styles.module.scss';

export interface ManageProfileItem {
  pageType?: string;
}

const ManageProfiles: React.FC<ManageProfileItem> = props => {
  const [pageType, setPageType] = useState(!props?.pageType ? MANAGE_PROFILE : props?.pageType);
  const { customerProfiles, selectedProfile, defaultProfile } = useCustomerProfile();
  const [profile, setProfile] = useState<CustomerProfileItem>();
  const dispatch = useDispatch();
  const history = useHistory();
  const { isMediaM } = useMedia();

  const onIconClickHandler = userSelectedProfile => {
    if (pageType === SELECT_PROFILE && userSelectedProfile.id !== ADD_PROFILE) {
      dispatch(actions.customer.setSelectedProfileId(userSelectedProfile.id));
      history.push('/');
    } else {
      setProfile(userSelectedProfile);
    }
  };

  const onDoneClickHandler = () => {
    setPageType(pageType === SELECT_PROFILE ? MANAGE_PROFILE : SELECT_PROFILE);
  };

  const onLogoClickHandler = () => {
    dispatch(
      actions.customer.setSelectedProfileId(
        !selectedProfile ? defaultProfile?.id : selectedProfile?.id,
      ),
    );
    history.push('/');
  };

  const onProfileClose = useCallback(() => setProfile(undefined), []);

  useEffect(() => {
    if (pageType === SELECT_PROFILE) {
      pageViewedAnalytics('Profile Selection');
    }
  }, [pageType]);

  return (
    <>
      <div className={styles.headerContainer}>
        <div className={styles.headerContent}>
          <span className={styles.headerLogo}>
            <Logo onClick={onLogoClickHandler} className={styles.appLogoIcon} />
          </span>
        </div>
      </div>
      {profile ? (
        <ManageProfile selectedCustomerProfile={profile} onClose={onProfileClose} />
      ) : (
        <div className={styles.manageProfilesContainer}>
          <div className={styles.manageProfilesTitle}>
            {pageType === MANAGE_PROFILE ? 'Manage Profiles' : "Who's watching?"}
          </div>
          <div className={styles.manageProfilesAvatar}>
            {customerProfiles?.map(customerProfile => {
              return (
                <Avatar
                  key={customerProfile?.id}
                  size={!isMediaM ? 64 : 140}
                  title={customerProfile.name}
                  profileImage={customerProfile.customerProfileAvatar?.image?.uri}
                  isEdit={pageType === MANAGE_PROFILE}
                  onClick={() =>
                    onIconClickHandler({
                      id: customerProfile.id,
                      name: customerProfile.name,
                      avatar: customerProfile.customerProfileAvatar?.image?.uri,
                      isDefault: customerProfile.isDefault,
                    })
                  }
                  className={classnames(
                    styles.manageProfileAvatarsOverride,
                    pageType === MANAGE_PROFILE && styles.avatarOpacity,
                  )}
                />
              );
            })}
            {customerProfiles?.length < 5 && (
              <Avatar
                size={!isMediaM ? 64 : 140}
                title="Add Profile"
                profileImage={icon_plus}
                onClick={() =>
                  onIconClickHandler({
                    id: ADD_PROFILE,
                  })
                }
                className={classnames(
                  styles.manageProfileAvatarsOverride,
                  pageType === MANAGE_PROFILE && styles.avatarOpacity,
                )}
              />
            )}
          </div>

          <div className={styles.manageProfilesButton}>
            <Button
              className={styles.button}
              type="primary"
              data-testid="manage-profile-done-button"
              name="done"
              onClick={onDoneClickHandler}
            >
              {pageType === MANAGE_PROFILE ? 'DONE' : 'MANAGE PROFILES'}
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default ManageProfiles;
